import React, { useEffect, useState } from "react";
import { InformationBar } from "../../Common/HelperComponents";
import { Row, Col, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import PopUp from "../../CustomControls/PopUp/PopUp";
import MultiSelect from "../../CustomControls/DropDowns/MultiSelect";
import { Roles } from "../../../Configurations/Constants";
import { Downloader, isExists } from "../../../Helpers/Utils";
import {
  exportMatchingTandemData,
  exportUserDataByRole,
  getClusters,
  getStatusMaster,
  getUserStatuses,
} from "../../../Services/Admin/AdminService";
import { useErrorHandler } from "react-error-boundary";
import SingleSelect from "../../CustomControls/DropDowns/SingleSelect";

const ExportExcel = (props) => {
  const { t: translate } = useTranslation();
  const { show, onActionClick } = props;
  const handleError = useErrorHandler();

  const [custers, setClusters] = useState([]);

  const [userStatuses, setUserStatuses] = useState([]);

  useEffect(() => {
    populateClusters();
    populateUserStatuses();
  }, []);

  const populateClusters = async () => {
    await getClusters()
      .then((response) => {
        if (response && response.isSuccess) {
          setClusters(buildClusterOptions(response.value));
        }
      })
      .catch((error) => {
        handleError(error);
      });
  };

  const populateUserStatuses = async () => {
    await getUserStatuses()
      .then((response) => {
        if (response && response.length > 0) {
          setUserStatuses(buildStatusOptions(response));
        }
      })
      .catch((error) => {
        // handleError(error);
      });
  };

  const buildStatusOptions = (options) => {
    let allOptions = [];
    if (isExists(options)) {
      options //.filter(state => state[label] !== Constants.StatusByRole.PAUSED)
        .forEach((option) => {
          const modifiedOption = {
            ...option,
            label: translate(option["name"]),
            value: option["id"],
          };
          allOptions.push(modifiedOption);
        });
    }
    return allOptions;
  };

  const buildClusterOptions = (options) => {
    let items = [];
    if (isExists(options)) {
      options.forEach((option) => {
        const modifiedOption = {
          option,
          label: translate(option["clusterText"]),
          value: option["clusterId"],
        };
        items.push(modifiedOption);
      });
    }
    return items;
  };

  const helperData = {
    exportMentorMenteeData: [
      {
        label: translate("export-basic-account-info"),
        value: "includeBasicAccountInformation",
      },
      {
        label: translate("export-extended-account-info"),
        value: "includeExtededAccountInformation",
      },
      {
        label: translate("export-profile-info"),
        value: "includeProfileInformation",
      },
    ],
    exportMatchingdata: [
      {
        label: translate("export-user-account-info"),
        value: "includeUserAccountInformation",
      },
      {
        label: translate("export-matchingtandem-info"),
        value: "includeMatchingTandemInformation",
      },
      {
        label: translate("export-proposal-info"),
        value: "includeProposalInformation",
      },
    ],
  };

  const [formData, setFormdata] = useState({
    selectedOption: null,
    isUserDataSelected: true,
    role: Roles.Mentee,
    exportMentorMenteeData: helperData.exportMentorMenteeData,
    selectedMentorMenteeData: {
      selectedOptions: helperData.exportMentorMenteeData,
    },
    exportMatchingdata: helperData.exportMatchingdata,
    selectedMatchingdata: { selectedOptions: helperData.exportMatchingdata },
    clusterIds: [],
    statuses: [],
  });

  const getReponseFromPopUp = (value) => {
    if (value) {
      if (canProceedForExport()) {
        if (formData.isUserDataSelected) {
          exportUserDataByRole(getUserDataForAPI())
            .then((res) => {
              const filename = `MentorMe_${formData.role.toLowerCase()}_user_data.xlsx`;
              downloadFile(res, filename);
            })
            .catch((error) => {
              handleError(error);
            });
        } else {
          exportMatchingTandemData(getMatchingTandemdata())
            .then((res) => {
              const filename = "MentorMe_matching_teams_data.xlsx";
              downloadFile(res, filename);
            })
            .catch((error) => {
              handleError(error);
            });
        }
        onActionClick(value);
      }
    } else {
      onActionClick(value);
    }
  };

  const downloadFile = (response, filename) => {
    const header =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    const url = window.URL.createObjectURL(
      new Blob([response], { type: header })
    );
    Downloader(url, filename)();
  };

  const getMatchingTandemdata = () => {
    let data = {
      includeUserAccountInformation: false,
      includeMatchingTandemInformation: false,
      includeProposalInformation: false,
      tandemDateRange: null,
    };

    formData.selectedMatchingdata.selectedOptions.forEach((eachSelection) => {
      if (eachSelection.value) {
        data[eachSelection.value] = true;
      }
    });

    if (formData.tandemDateRange) {
      data.tandemDateRange = formData.tandemDateRange;
    }

    return data;
  };

  const getUserDataForAPI = () => {
    let data = {
      role: formData.role,
      includeBasicAccountInformation: false,
      includeExtededAccountInformation: false,
      includeProfileInformation: false,
      filterData: {
        clusterIds: null,
        statuses: null,
      },
    };

    formData.selectedMentorMenteeData.selectedOptions.forEach(
      (eachSelection) => {
        if (eachSelection.value) {
          data[eachSelection.value] = true;
        }
      }
    );

    if (formData.role === Roles.Mentee && isExists(formData.clusterIds)) {
      data.filterData.clusterIds = formData.clusterIds;
    }

    if (formData.role === Roles.Mentor && isExists(formData.statuses)) {
      data.filterData.statuses = formData.statuses;
    }

    return data;
  };

  const canProceedForExport = () => {
    const {
      isUserDataSelected,
      selectedMentorMenteeData,
      selectedMatchingdata,
    } = formData;
    if (isUserDataSelected) {
      return isExists(selectedMentorMenteeData.selectedOptions);
    }
    return isExists(selectedMatchingdata.selectedOptions);
  };

  const inputFromMenteeMentorData = (val) => {
    setFormdata({
      ...formData,
      selectedMentorMenteeData: val,
    });
  };

  const inputFromProgramYear = (val) => {
    if (val && isExists(val.selectedOptions)) {
      const selectedClusters = [];
      val.selectedOptions.forEach((selectedItem) =>
        selectedClusters.push(selectedItem.option)
      );
      setFormdata({
        ...formData,
        clusterIds: selectedClusters,
      });
    }
  };

  const inputFromUserStatus = (val) => {
    if (val && isExists(val.selectedOptions)) {
      const selectedStatuses = [];
      val.selectedOptions.forEach((selectedItem) =>
        selectedStatuses.push(selectedItem)
      );
      setFormdata({
        ...formData,
        statuses: selectedStatuses,
      });
    }
  };

  const inputFromMatchingData = (val) => {
    setFormdata({
      ...formData,
      selectedMatchingdata: val,
    });
  };

  const inputFromTandemDateRange = (val) => {
    if (!val || !val.selectedOptions) return;
    setFormdata({
      ...formData,
      tandemDateRange: val.selectedOptions.value,
    });
  };

  const setExportData = (val, role) => {
    setFormdata({
      ...formData,
      isUserDataSelected: val,
      role: role || Roles.Mentee,
    });
  };

  return (
    <PopUp
      show={show}
      size="xl"
      acceptName={translate("export-as-excel")}
      rejectName={translate("Cancel")}
      btnDirection="start"
      btnTop="m-top-60"
      sendResponse={getReponseFromPopUp}
    >
      <Form>
        <Col sm={12} lg={12} md={12}>
          <Row>
            <Col sm={12} lg={12} md={12}>
              <div className="m-bottom-22 p-left-0">
                <div key={`inline-radio`} className="mb-3">
                  <Form.Check
                    className="export-checkbox"
                    checked={
                      formData.isUserDataSelected &&
                      formData.role === Roles.Mentee
                    }
                    inline
                    onChange={() => setExportData(true, Roles.Mentee)}
                    label={translate("export-mentee-user-data")}
                    name="group1"
                    id="default-radio-1"
                    type={"radio"}
                  />
                  <Form.Check
                    className="export-checkbox"
                    checked={
                      formData.isUserDataSelected &&
                      formData.role === Roles.Mentor
                    }
                    onChange={() => setExportData(true, Roles.Mentor)}
                    inline
                    label={translate("export-mentor-user-data")}
                    name="group1"
                    id="default-radio-2"
                    type={"radio"}
                  />
                </div>
              </div>
            </Col>
            <Col sm={12} lg={12} md={12}>
              <InformationBar
                info
                addons="m-bottom-35"
                message={translate("export-user-data-info")}
              />
            </Col>
            <Col sm={12} lg={12} md={12}>
              <div className="p-left-0">
                <p className="p3-medium-light-1 m-bottom-18">
                  {translate("export-select-sub-heading")}
                </p>
                <MultiSelect
                  error={
                    formData.isUserDataSelected &&
                    !isExists(formData.selectedMentorMenteeData.selectedOptions)
                  }
                  errorMessage={translate("requiredFieldError")}
                  selectedItems={inputFromMenteeMentorData}
                  checked={!formData.isUserDataSelected}
                  successTick={false}
                  placeholder={translate("Please select a value…")}
                  preSelectedOptions={
                    formData.selectedMentorMenteeData.selectedOptions
                  }
                  options={formData.exportMentorMenteeData}
                />
              </div>
            </Col>
            <Col sm={12} lg={3} md={12}>
              <div className="p-left-0 mt-4">
                {formData.role === Roles.Mentee && (
                  <>
                    <p className="p3-medium-light-1 m-bottom-18">
                      {"Program Year"}
                    </p>
                    <MultiSelect
                      selectedItems={inputFromProgramYear}
                      checked={!formData.isUserDataSelected}
                      successTick={false}
                      placeholder={translate("Please select a value…")}
                      options={custers}
                    />{" "}
                  </>
                )}
                {formData.role === Roles.Mentor && (
                  <>
                    <p className="p3-medium-light-1 m-bottom-18">{"Status"}</p>
                    <MultiSelect
                      selectedItems={inputFromUserStatus}
                      checked={!formData.isUserDataSelected}
                      successTick={false}
                      placeholder={translate("Please select a value…")}
                      options={userStatuses}
                    />
                  </>
                )}
              </div>
            </Col>
          </Row>
          <Row className="m-top-59">
            <Col sm={12} lg={12} md={12}>
              <div className="m-bottom-22 p-left-0">
                <Form.Check
                  className="export-checkbox"
                  inline
                  onChange={() => setExportData(false)}
                  label={translate("export-matchingtandem-user-data")}
                  name="group1"
                  id="default-radio-3"
                  type={"radio"}
                />
              </div>
            </Col>
            <Col sm={12} lg={12} md={12}>
              <InformationBar
                info
                addons="m-bottom-25"
                message={translate("export-matching-tandem-data-info")}
              />
            </Col>
            <Col sm={12} lg={12} md={12}>
              <div className="p-left-0">
                <p className="p3-medium-light-1 m-bottom-18">
                  {translate("export-select-sub-heading")}
                </p>
                <MultiSelect
                  selectedItems={inputFromMatchingData}
                  successTick={false}
                  error={
                    !formData.isUserDataSelected &&
                    !isExists(formData.selectedMatchingdata.selectedOptions)
                  }
                  errorMessage={translate("requiredFieldError")}
                  placeholder={translate("Please select a value…")}
                  preSelectedOptions={
                    formData.selectedMatchingdata.selectedOptions
                  }
                  options={formData.exportMatchingdata}
                />
              </div>
            </Col>
            <Col sm={12} lg={3} md={12}>
              <div className="p-left-0 mt-4">
                <p className="p3-medium-light-1 m-bottom-18">{"Last"}</p>
                <SingleSelect
                  // preSelectedOptions={formData.tandemDateRange}
                  selectedItems={inputFromTandemDateRange}
                  // error={isInputError.isMenteeError}
                  // errorMessage={translate("requiredFieldError")}
                  options={[
                    { label: "1 Week", value: 7 },
                    { label: "1 Month", value: 30 },
                    { label: "3 Months", value: 90 },
                    { label: "6 Months", value: 180 },
                    { label: "1 Year", value: 365 },
                  ]}
                  successTick={false}
                  placeholder={translate("Please select a value…")}
                  // serachIcon
                />
              </div>
            </Col>
          </Row>
        </Col>
      </Form>
    </PopUp>
  );
};

export default ExportExcel;

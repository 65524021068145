import * as Configs from '../Configurations/Constants';
import { msalInstance } from "../index";
import { apiEndpoints } from '../Configurations/Constants';
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { hideLoader, isExists, showLoader } from '../Helpers/Utils';

const axios = require('axios').default;
export const Post = async (endpoint, data, isAuthRequired = true) => {
    try {
        const headers = await postHeaders(isAuthRequired);
        if (endpoint === apiEndpoints.User.UpdateProfileImage) {
            headers['content-type'] = 'multipart/form-data';
        }

        let configObj = {};
        if (
          endpoint === Configs.apiEndpoints.Admin.ExportMatchingTandemData ||
          endpoint === Configs.apiEndpoints.Admin.ExportUserDataByRole
        ) {
          configObj = {
            responseType: "arraybuffer",
          };
        }

        return await axios
          .post(Configs.MentorMeApiBaseUrl + endpoint, data, {
            ...configObj,
            headers: headers,
          })
          .then((res) => res.data)
          .catch((error) => {
            getErrorDetails(error, endpoint);
            throw error;
          });
    }
    catch (error) {
        getErrorDetails(error, endpoint);
        throw error;
    }
}

export async function Get(endpoint, params, isAuthRequired = true) {
    try {
        const headers = await getHeaders(isAuthRequired);
        let configObj = {
            headers: headers,
            params: params
        }

        if (endpoint === Configs.apiEndpoints.Admin.ExportMatchingTandemData ||
            endpoint === Configs.apiEndpoints.Admin.ExportUserDataByRole) {
            configObj = {
                ...configObj,
                responseType: 'arraybuffer'
            }
        }

        return await axios.get(Configs.MentorMeApiBaseUrl + endpoint, configObj)
            .then(res => res.data)
            .catch((error) => {
                getErrorDetails(error, endpoint);
                throw error;
            });
    }
    catch (error) {
        getErrorDetails(error, endpoint);
        throw error;
    }
}

axios.interceptors.request.use(function (config) {
    // before request is sent
    if (!config?.url?.includes(apiEndpoints.LogError))
        showLoader();
    return config;
}, function (error) {
    //something with request error
    hideLoader();
    return Promise.reject(error);
});

axios.interceptors.response.use(function (response) {
    // something with response data
    hideLoader();
    return response;
}, function (error) {
    // something with response error
    hideLoader();
    return Promise.reject(error);
});

async function getToken() {
    const account = msalInstance.getActiveAccount();
    if (!account) {
        let errorDetails = new Error();
        errorDetails.code = 401;
        throw errorDetails;
    }
    if (account) {
        return await msalInstance.acquireTokenSilent({
            scopes: apiEndpoints.scopes,
            account: account
        })
            .then((response) => response.accessToken)
            .catch((error) => {
                if (error instanceof InteractionRequiredAuthError) {
                    if (account) {
                        msalInstance.acquireTokenPopup({
                            scopes: apiEndpoints.scopes,
                        }).then((response) => response.accessToken)
                            .catch(error => {
                                let errorDetails = new Error();
                                errorDetails.name = "Couldn't get token"
                                errorDetails.stack = isExists(error.stack) && error.stack;
                                errorDetails.message = error.message;
                                errorDetails.code = 401;
                                throw errorDetails;
                            });
                    }
                }
            })
    }
}

async function getHeaders(isAuthRequired) {
    try {
        let headers = {
            accept: 'application/json',
        }
        if (isAuthRequired) {
            const token = await getToken();
            headers = {
                ...headers,
                authorization: `Bearer ${token}`
            }
        }
        return headers;
    }
    catch (err) {
        throw err;
    }
}

async function postHeaders(isAuthRequired) {
    try {
        let headers = {
            'content-type': 'application/json'
        }
        if (isAuthRequired) {
            const token = await getToken();
            headers = {
                ...headers,
                authorization: `Bearer ${token}`
            }
        }
        return headers;
    }
    catch (err) {
        throw err;
    }
}

function getErrorDetails(error, endpoint) {
    let errorDetails = new Error();
    errorDetails.name = endpoint;
    errorDetails.stack = isExists(error.stack) && error.stack;
    errorDetails.message = error.message;
    if (error.response) {
        /*
         * The request was made and the server responded with a status 
         * code that falls out of the range of 2xx
         */
        errorDetails.code = error.response.status;
    }
    else {
        // Something happened in setting up the request and triggered an Error
        errorDetails.code = error.code ? error.code : 500;
    }
    throw errorDetails;
}
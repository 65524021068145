import { Get, Post } from "../service";
import { apiEndpoints, Roles } from "../../Configurations/Constants";

export async function getAllUsers() {
  const requestEndpoint = apiEndpoints.Admin.GetAllUsers;
  return await Get(requestEndpoint)
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
}

export async function getUserDetail(emailId, language) {
  const requestEndpoint =
    apiEndpoints.Admin.GetUserDetail + `?email=${emailId}&language=${language}`;
  return await Get(requestEndpoint)
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
}

export async function getMasterDataForMenteeStateChange(emailId) {
  const requestEndpoint =
    apiEndpoints.Admin.GetMasterDataForMenteeStateChange + `?email=${emailId}`;
  return await Get(requestEndpoint)
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
}

export async function updateMenteeInformation(data) {
  return await Post(apiEndpoints.Admin.UpdateMenteeInformation, data)
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
}

export async function createMatchingTandemManual(data) {
  return await Post(apiEndpoints.Admin.CreateMatchingTandemManual, data)
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
}

export async function getManualMatchingMasterData() {
  return await Get(apiEndpoints.Admin.GetManualMatchingMasterData)
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
}

export async function updateUserStatusForMentor(email, val) {
  const data = {
    email,
    roleName: Roles.Mentor,
    disableMatching: val,
  };
  return await Post(apiEndpoints.Admin.UpdateUserStatus, data)
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
}

export async function deleteUserFormAzureAD(email) {
  const api = `${apiEndpoints.Admin.DeleteUserFromAzureAd}?emailId=${email}&deletefromDB=true`;
  return await Get(api)
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
}

export async function getMatchingTandemList(data) {
  return await Post(apiEndpoints.Admin.GetMatchingTandemList, data)
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
}

export async function getStatusMaster() {
  return await Get(apiEndpoints.Admin.StatusMaster)
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
}

export async function getMentees(data) {
  return await Post(apiEndpoints.Admin.GetMentees, data)
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
}

export async function getMentors(data) {
  return await Post(apiEndpoints.Admin.GetMentors, data)
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
}
export async function getMatchingTandemById(id) {
  const data = { id };
  const endPoint =
    apiEndpoints.Admin.GetMatchingTandemById + `?matchingTandemId=${id}`;
  return await Post(endPoint, data)
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
}

export async function releaseMatchingTandemManual(data) {
  return await Post(apiEndpoints.Admin.CloseMatchingTandem, data)
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
}

export async function createUpdateCluster(text) {
  const endPoint = apiEndpoints.Admin.CreateUpdateCluster + `?yearText=${text}`;
  return await Post(endPoint, null)
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
}

export async function getClusters() {
  return await Get(apiEndpoints.Admin.GetClusters)
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
}

export async function getUserStatuses() {
  return await Get(apiEndpoints.User.getAllStatus)
    .then((res) => res)
    .catch((error) => {
      return null;
      // throw error;
    });
}

export async function closeCluster(clusterId) {
  const request = apiEndpoints.Admin.CloseCluster + `?clusterId=${clusterId}`;
  return await Get(request)
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
}
export async function declineProposal(data) {
  return await Post(apiEndpoints.Admin.DeclineProposal, data)
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
}
export async function acceptProposal(data) {
  return await Post(apiEndpoints.Admin.AcceptProposal, data)
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
}

export async function syncDBFromAzureAD() {
  return await Get(apiEndpoints.Admin.SyncDBFromAzureAD)
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
}

export async function exportUserDataByRole(data) {
  return await Post(apiEndpoints.Admin.ExportUserDataByRole, data)
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
}

export async function exportMatchingTandemData(data) {
  return await Get(apiEndpoints.Admin.ExportMatchingTandemData, data)
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
}

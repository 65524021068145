import config from "./Config";

export const MentorMeApiBaseUrl = config.REACT_APP_MENTORME_API_URL;
export const MentorMeUIBaseUrl = config.REACT_APP_MENTORME_UI_URL;

export const BlobStorageImages = config.REACT_APP_BLOB_COMMON_IMG_PATH;
export const BlobStorageFonts = config.REACT_APP_BLOB_COMMON_FONT_PATH;
export const BlobStorageCSS = config.REACT_APP_BLOB_COMMON_CSS_PATH;

export const Azure_App_Name = config.REACT_APP_AZURE_APP_NAME;
export const Azure_Client_Id = config.REACT_APP_AZURE_CLIENT_ID;
export const Azure_API_Scope = config.REACT_APP_AZURE_SCOPE;

export const AzureADB2CSetings = {
  domain: `${Azure_App_Name}.onmicrosoft.com`,
  authDomain: `${Azure_App_Name}.b2clogin.com`,
  b2cPolicies: {
    SignInSignUp: "B2C_1_SignIn_SignUp",
  },
  clientId: Azure_Client_Id,
};
export const RolesParams = ["register-as-mentee", "register-as-mentor"];
export const Roles = {
  Mentee: "Mentee",
  Mentor: "Mentor",
  Both: "Both",
  Admin: "Admin",
};
export const StatusByRole = {
  MATCHED: "MATCHED",
  PAUSED: "PAUSED",
  AVAILABLE: "AVAILABLE",
  PROPOSED: "PROPOSED",
  INACTIVE: "INACTIVE",
  NEW: "NEW",
  PENDING: "PENDING",
};

export const PublicPages = {
  TermsAndCondition: {
    name: "termsandcondition",
    path: "/terms-and-conditions",
    pageTitle: "termsAndConditions",
  },
  DataPrivacyPolicy: {
    name: "dataprivacypolicy",
    path: "/data-privacy-policy",
    pageTitle: "dataPrivacyPolicy",
  },
  LegalInfo: { name: "legal", path: "/legal-info", pageTitle: "legalInfo" },
};

export const Pages = {
  Home: { name: "/", path: "/", pageTitle: "Dashboard" },
  Dashboard: { name: "dashboard", path: "/matching", pageTitle: "Dashboard" },
  RegisterAsMentor: {
    name: "register-as-mentor",
    path: "/register-as-mentor",
    pageTitle: "",
  },
  RegisterAsMentee: {
    name: "register-as-mentee",
    path: "/register-as-mentee",
    pageTitle: "",
  },

  UserProfile: {
    name: "user-profile",
    path: "/user/account",
    pageTitle: "User account",
  },
  QuestionnaireMentor: {
    name: "questionnaire-mentor",
    path: "/user/mentor-profile",
    pageTitle: "Questionnaire mentor",
  },
  QuestionnaireMentee: {
    name: "Questionnaire-mentee",
    path: "/user/mentee-profile",
    pageTitle: "Questionnaire mentee",
  },

  AdminDashBoard: {
    name: "adminDashboard",
    path: "/administrator/matching-tandems",
    pageTitle: "matching-tandems",
  },
  AdminMentees: {
    name: "adminMentees",
    path: "/administrator/mentees",
    pageTitle: "mentees",
  },
  AdminMentors: {
    name: "adminMentors",
    path: "/administrator/mentors",
    pageTitle: "mentors",
  },
  AdminUserInformation: {
    name: "adminUserInformation",
    path: "/administrator/user-information",
    pageTitle: "admin-user-info-title",
  },
  AdminProgramYear: {
    name: "adminProgramYear",
    path: "/administrator/clusters",
    pageTitle: "program-year",
  },
  ...PublicPages,

  Error: { name: "ErrorPage", path: "/:other", pageTitle: "Error" },
};

export const apiEndpoints = {
  scopes: [Azure_API_Scope],
  Logger: {
    LogError: "/api/Logger/LogError",
  },
  Account: {
    RegisterUser: "/api/account/signup",
    GetUserDetails: "/api/Account/GetUserDetails",
    SignIn: "/api/Account/signin",
  },
  Notification: {
    SendNotification: "/api/Notification/NotifyUser",
  },
  User: {
    UpdateProfile: "/api/user/UpdateProfile",
    GetUserProfile: "/api/user/GetUserProfile",
    UpdateProfileImage: "/api/user/UpdateProfileImage",
    GetProposedUserProfile: "/api/User/GetProposedUserProfile",
    getAllStatus: "/api/User/GetAllStatus",
  },
  Questionnaire: {
    GetQuestionAnswerPairs: "/api/Questionnaire/GetQuestionAnswerPairs",
    CreateAnswer: "/api/Questionnaire/CreateAnswer",
    UpdateAnswer: "/api/Questionnaire/UpdateAnswer",
    UpdateUserStatus: "/api/Questionnaire/UpdateUserStatus",
  },
  MatchingTandem: {
    CreateMatchingTandem: "/api/MatchingTandem/CreateMatchingTandem",
    GetProposals: "/api/MatchingTandem/GetProposals",
    AcceptProposal: "/api/MatchingTandem/AcceptProposal",
    DeclineProposal: "/api/MatchingTandem/DeclineProposal",
    DeclineReason: "/api/MatchingTandem/GetDeclineReason",
  },
  Admin: {
    GetAllUsers: "/api/Admin/GetAllUsers",
    GetUserDetail: "/api/Admin/GetUserDetail",
    GetManualMatchingMasterData: "/api/Admin/GetManualMatchingMasterData",
    CreateMatchingTandemManual: "/api/Admin/CreateMatchingTandemManual",
    UpdateUserStatus: "/api/Admin/UpdateUserStatus",
    GetMatchingTandemList: "/api/Admin/GetMatchingTandemList",
    StatusMaster: "/api/Admin/GetStatusMaster",
    GetMentees: "/api/Admin/GetMentees",
    GetMentors: "/api/Admin/GetMentors",
    GetMatchingTandemById: "/api/Admin/GetMatchingTandemById",
    CloseMatchingTandem: "/api/Admin/CloseMatchingTandem",
    GetClusters: "/api/Admin/GetClusters",
    CreateUpdateCluster: "/api/Admin/CreateCluster",
    SyncDBFromAzureAD: "/api/Admin/SyncDBFromAzureAD",
    DeclineProposal: "/api/Admin/DeclineProposal",
    AcceptProposal: "/api/Admin/AcceptProposal",
    ExportUserDataByRole: "/api/Admin/ExportUserDataByRole",
    ExportMatchingTandemData: "/api/Admin/ExportMatchingTandemData",
    GetMasterDataForMenteeStateChange:
      "/api/Admin/GetMasterDataForMenteeStateChange",
    UpdateMenteeInformation: "/api/Admin/UpdateMenteeInformation",
    CloseCluster: "/api/Admin/CloseCluster",
    DeleteUserFromAzureAd: "/api/Account/DeleteUserFromAzureAD",
  },
};

export const InAppImages = {
  logo: `${BlobStorageImages}/logo.svg`,
  profileIcon: `${BlobStorageImages}/defaultProfileIcon.svg`,
};

export const Language = {
  English: { Code: "en", Name: "English" },
  Deutsch: { Code: "de", Name: "German", DisplayName: "Deutsch" },
};
export const PageContent = {
  TermsAndCondition_en: require("./PublicPageContent/termsAndCondtionText_en")
    .default,
  TermsAndCondition_de: require("./PublicPageContent/termsAndCondtionText_de")
    .default,
  DataPrivacyPolicy_en: require("./PublicPageContent/dataPrivacyPolicyText_en")
    .default,
  DataPrivacyPolicy_de: require("./PublicPageContent/dataPrivacyPolicyText_de")
    .default,
};

export const SocialMediaLinks = {
  LinkedIn: { link: "//www.linkedin.com/company/mentorme" },
  Instagram: { link: "//www.instagram.com/mentorme_berlin" },
  Facebook: { link: "//www.facebook.com/MentorMeBerlin" },
  MentormeWebsite: { link: "//www.mentorme-ngo.org" },
};

export const LegalLinks = {
  termsAndConditions: { link: Pages.TermsAndCondition.path },
  dataPrivacyPolicy: { link: Pages.DataPrivacyPolicy.path },
  legalInfo: { link: Pages.LegalInfo.path },
};

export const NavLinks = {
  dashboard: "Dashboard",
  mentee: "Mentee",
  mentor: "Mentor",
  profile: "Profile",
  adminUserInformation: "AdminUserInformation",
  adminDashboard: "adminDashboard",
  adminMentees: "adminMentees",
  adminMentors: "adminMentors",
  adminProgramYear: "adminProgramYear",
};

export const InfobarTemplates = {
  pause: `Currently you are in pause mode. You will not matched with a Mentee." OR (if admin pause questionnaire) "The administrator changed your profile mode. Currently you are in pause mode. You will not matched with a Mentee. Please contact the administrator to change this.`,
  common: `Your answers will be treated with care. We do not publish profile information. The following details will be displayed only proposed mentors: Selected industries, activities and mentoring areas, as well as gender and personal background.`,
};

export const MessageBarTemplates = {
  General: {
    questionnaireInfo: `Your answers will be treated with care. We do not publish profile information. The following details will be displayed only proposed mentors: Selected industries, activities and mentoring areas, as well as gender and personal background.`,
    userProfileImgCriteria: `Only images of type JPG, JPEG, PNG and GIF are valid. Please make sure that your image size do not exceed 5 MB`,
    profileMissingFieldsError: `profileMissingFieldsError`,
    requiredFieldError: `requiredFieldError`,
  },
  Both: {
    infoBarMessages: {
      profile: `profile-infoBarText-Both`,
    },
  },
  Admin: {
    infoBarMessages: {
      profile: `profile-infoBarText-Both`,
    },
  },
  Mentee: {
    saved: {
      message: `Your data has been saved. Please note: Click "start matching" to start the matching process.`,
      messageType: `warning`,
    },
    paused: {
      message: `Your profile is now paused. That means you can't be proposed to any mentees at the moment. We hope you will be back soon.`,
      messageType: `warning`,
    },
    infoBarMessages: {
      profile: `profile-infoBarText-Mentor`,
    },
    matchingTandem: {
      subline: `matching-tandem-mentee-subline`,
      info: `matching-tandem-mentee-info`,
      accept: `matching-tandem-mentee-confirmation-accept`,
      decline: `matching-tandem-mentee-confirmation-decline`,
      comment: `matching-tandem-confirmation-comment`,
      acceptBtnTxt: "Accept mentor",
      declineBtnTxt: "Decline mentor",
    },
    noMatchingProposalYet: {
      subline: "matching-tandem-no-matching-proposal-yet-subline-mentee",
      info: "matching-tandem-no-matching-proposal-yet-info-mentee",
      actionButton: "start-matching",
    },
    noProposalsFound: {
      subline: "matching-tandem-no-proposal-found-subline",
      info: "matching-tandem-no-proposal-found-info-mentee",
      actionButton: "adjust-profile",
    },
    noProfile: {
      subline: "matching-tandem-no-profile-subline-mentee",
      info: "matching-tandem-no-profile-info-mentee",
      actionButton: "create-mentee-profile",
    },
  },
  Mentor: {
    saved: {
      message: `Your data has been saved. Please note: Your profile is not yet in the matching pool. Click "save & publish" to start the matching process.`,
      messageType: `warning`,
    },
    paused: {
      message: `Your profile is now paused. That means you can't be proposed to any mentees at the moment. We hope you will be back soon.`,
      messageType: `warning`,
    },
    infoBarMessages: {
      profile: `profile-infoBarText-Mentee`,
    },
    matchingTandem: {
      subline: `matching-tandem-mentor-subline`,
      info: `matching-tandem-mentor-info`,
      accept: `matching-tandem-mentor-confirmation-accept`,
      decline: `matching-tandem-mentor-confirmation-decline`,
      comment: `matching-tandem-confirmation-comment`,
      acceptBtnTxt: "Accept mentee",
      declineBtnTxt: "Decline mentee",
    },
    noMatchingProposalYet: {
      subline: "matching-tandem-no-matching-proposal-yet-subline-mentor",
      info: "matching-tandem-no-matching-proposal-yet-info-mentor",
      actionButton: "publish-profile",
    },
    noProposalsFound: {
      subline: "matching-tandem-no-proposal-found-subline",
      info: "matching-tandem-no-proposal-found-info-mentor",
      actionButton: "See profile",
    },
    noProfile: {
      subline: "matching-tandem-no-profile-subline-mentor",
      info: "matching-tandem-no-profile-info-mentor",
      actionButton: "create-mentor-profile",
    },
  },
};

export const MessageTypes = {
  error: "error",
  warning: "warning",
  success: "success",
};

export const NotificationTemplateType = {
  NewTandem: "NEW_TANDEM",
  ApproveTandem: "APPROVE_TANDEM",
  DeclineTandem: "DECLINE_TANDEM",
  ActivateUserProfile: "ACTIVATE_USER_PROFILE",
  DeactivateUserProfile: "DEACTIVATE_USER_PROFILE",
  ReminderActiveTandem: "REMINDER_ACTIVE_TANDEM",
  ReminderUnpublishedUserProfile: "REMINDER_UNPUBLISHED_USER_PROFILE",
};

export const QuestionnaireRule = {
  SingleFit: "SingleFit",
  YearsFit: "YearsFit",
  MultipleFit: "MultipleFit",
  MultipleMatchesFit: "MultipleMatchesFit",
  MentorOnly: "MentorOnly",
};

export const UserRoleInformationState = {
  NEW: "NEW",
  AVAILABLE: "AVAILABLE",
  PROPOSED: "PROPOSED",
  MATCHED: "MATCHED",
  INACTIVE: "INACTIVE",
  PAUSED: "PAUSED",
  PENDING: "PENDING",
};

export const ProposalStates = {
  DECLINED: "DECLINED",
  ACCEPTED: "ACCEPTED",
  PROPOSED: "PROPOSED",
  CLOSED: "CLOSED",
  MATCHED: "MATCHED",
  ACCEPTED_PROPOSAL: "accepted-proposal",
  MATCHED_PROPOSAL: "matched-proposal",
};

export const TandemStates = {
  MATCHED: "MATCHED",
  ACTIVE: "ACTIVE",
  DECLINED: "DECLINED",
  CLOSED: "CLOSED",
  NOREACTION: "NOREACTION",
};

export const AllStates = {
  ACTIVE: { key: "ACTIVE", type: "warning" },
  INACTIVE: { key: "INACTIVE", type: "info" },
  PROPOSED: { key: "PROPOSED", type: "warning" },
  MATCHED: { key: "MATCHED", type: "success" },
  ACCEPTED: { key: "ACCEPTED", type: "success" },
  DECLINED: { key: "DECLINED", type: "error" },
  CLOSED: { key: "CLOSED", type: "info" },
  AVAILABLE: { key: "AVAILABLE", type: "error" },
  NEW: { key: "NEW", type: "info" },
  PENDING: { key: "PENDING", type: "warning" },
  PAUSED: { key: "PAUSED", type: "info" },
  MENTOR: { key: "Mentor", type: "info" },
  MENTEE: { key: "Mentee", type: "info" },
  NOREACTION: { key: "NOREACTION", type: "info" },
};

export const CommandType = {
  SaveAndPublish: "SaveAndPublish",
  StartMatching: "StartMatching",
  Save: "Save",
  PublishProfile: "PublishProfile",
  SeeProfile: "SeeProfile",
  AdjustProfile: "AdjustProfile",
  CreateMentor: "CreateMentor",
  CreateMentee: "CreateMentee",
  Accept: "Accept",
  Decline: "Decline",
};

export const MaxLimits = {
  DeclineReason: 1000,
  RowsPerPageInTable: 25,
};

export default {
  AllStates,
  AzureADB2CSetings,
  MentorMeApiBaseUrl,
  MentorMeUIBaseUrl,
  RolesParams,
  apiEndpoints,
  Pages,
  PublicPages,
  Roles,
  InAppImages,
  PageContent,
  Language,
  SocialMediaLinks,
  LegalLinks,
  NotificationTemplateType,
  NavLinks,
  InfobarTemplates,
  QuestionnaireRule,
  MessageTypes,
  MessageBarTemplates,
  UserRoleInformationState,
  StatusByRole,
  ProposalStates,
  TandemStates,
  CommandType,
  MaxLimits,
};
